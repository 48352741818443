import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import useOGImage from '../hooks/useOGImage';
import useSiteMetadata from '../hooks/useSiteMetadata';

function SEO({ description, lang, meta, location, keywords, title, image }) {
  const site = useSiteMetadata();
  const metaDescription = description || site.description;

  const OGImage = useOGImage();
  const metaImage = image || OGImage;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        { property: 'og:url', content: `${site.siteUrl}${location.pathname}` },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${site.siteUrl}${metaImage.src}`,
        },
        {
          property: `og:image:height`,
          content: metaImage.height,
        },
        {
          property: `og:image:width`,
          content: metaImage.width,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">{`
        {
          "@context": "http://www.schema.org",
          "@type": "EntertainmentBusiness",
          "image": ["${site.siteUrl}/photos/localBusiness_16x9.jpg", "${site.siteUrl}/photos/localBusiness_4x3.jpg","${site.siteUrl}/photos/localBusiness_1x1.jpg"],
          "@id": "${site.siteUrl}",
          "name": "${site.title}",
          "url": "${site.siteUrl}",
          "description": "${site.description}",
          "telephone": "+44 7860 839560",
          "areaServed": {
            "@type": "City",
            "name": "Bath"
          },
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "The Fold, Englishcombe Village",
            "addressLocality": "Bath",
            "addressRegion": "Somerset",
            "postalCode": "BA2 9DU",
            "addressCountry": "GB"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": "51.3641",
            "longitude": "-2.4103637"
          },
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "09:00",
              "closes": "22:00"
            }
          ]
        }
    `}</script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  location: PropTypes.object.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
};

export default SEO;
