import { graphql, useStaticQuery } from "gatsby";

const useOGImage = () => {
  const data = useStaticQuery(graphql`
    query {
      lessons: file(relativePath: { eq: "lessons.jpg" }) {
        sharp: childImageSharp {
          fixed(width: 1200) {
            width
            height
            src
          }
        }
      }
    }
  `);

  return data.lessons.sharp.fixed;
};

export default useOGImage;
